(function ($) {

  const auto_dismissing_alert_timeout = 5000;

  $(document).ready(function () {
    var body = $('body');
    body.bootstrapMaterialDesign();
    console.info('Welcome to GUISHELL', 'Version: ' + body.data("guishellversion"));

    setTimeout(function () {
      $("#auto-dismissing-alert").alert("close");
    }, auto_dismissing_alert_timeout);

  });

})(jQuery);
